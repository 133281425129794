import '../lib/setupLegacyStoreViews'
import '@emico/styles/reboot.css'
import '../theme/centro.css'
import '../overrides'

import { ApolloProvider } from '@apollo/client'
import getCatalog from '@catalogs'
import { CartIdProvider } from '@emico-hooks/cart-id'
import { LoginTokenProvider } from '@emico-hooks/login-token'
import { ActiveStoreViewProvider } from '@emico-hooks/use-active-storeview'
import { BreakpointsProvider as BreakpointsProviderV2 } from '@emico-react/breakpoints'
import { getStoreViewByLocale } from '@emico-utils/storeview-manager'
import { i18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import { SSRProvider } from '@react-aria/ssr'
import { en, be, da, de, uk, es, nl, se, fr, no } from 'make-plural/plurals'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { useRouter } from 'next/router'

import { PortalTarget } from '@emico/portal'
import { BreakpointsProvider } from '@emico/ui'

import { useApollo } from '../apollo/apolloClient'
import Meta from '../components/Meta'
import MetaOgDefaults from '../components/MetaOgDefaults'
import SchemaOrganization from '../components/SchemaOrganization'
import getSeoPageDescription from '../lib/getSeoPageDescription'
import getSeoPageTitle from '../lib/getSeoPageTitle'
import { localeLinguiMap } from '../lib/localeLinguiMap'
import { defaultStoreConfig } from '../lib/setupLegacyStoreViews'
import useUpdateCatalogs from '../lib/useUpdateCatalogs'
import GlobalStyles from '../theme/GlobalStyles'
import { RouteProps } from './[[...slug]].page'

i18n.loadLocaleData({
  en: { plurals: en },
  'da-DK': { plurals: da },
  'de-AT': { plurals: de },
  'de-DE': { plurals: de },
  'de-CH': { plurals: de },
  'nl-NL': { plurals: nl },
  'nl-BE': { plurals: be },
  'fr-BE': { plurals: fr },
  'fr-FR': { plurals: fr },
  'fr-CH': { plurals: fr },
  'en-GB': { plurals: uk },
  'en-IE': { plurals: en },
  'en-US': { plurals: en },
  es: { plurals: es },
  'sv-SE': { plurals: se },
  'nb-NO': { plurals: no },
})

function App({
  Component,
  pageProps,
}: Pick<AppProps<RouteProps>, 'Component'> & {
  pageProps: RouteProps
}) {
  // Get an Apollo client with the initial (pre-rendered) Apollo state.
  const apolloClient = useApollo(pageProps)

  // Load and activate the pre-rendered catalog
  const { defaultLocale = '', locale = defaultLocale } = useRouter()
  const linguiLocale = localeLinguiMap.get(locale) ?? locale

  const catalog = getCatalog(linguiLocale)

  if (catalog.locale === linguiLocale) {
    i18n.load({
      [linguiLocale]: catalog.messages,
    })
    i18n.activate(linguiLocale)
  }
  useUpdateCatalogs()

  return (
    <ActiveStoreViewProvider
      storeView={getStoreViewByLocale(locale) ?? defaultStoreConfig}
    >
      <SSRProvider>
        <PortalTarget>
          <I18nProvider i18n={i18n}>
            <GlobalStyles />

            <ApolloProvider client={apolloClient}>
              <Head>
                <meta
                  content="width=device-width, initial-scale=1.0"
                  name="viewport"
                />
              </Head>

              <Meta
                metaTitle={getSeoPageTitle()}
                metaDescription={getSeoPageDescription()}
              />
              <MetaOgDefaults />
              <SchemaOrganization />

              <BreakpointsProvider>
                <BreakpointsProviderV2>
                  <CartIdProvider>
                    <LoginTokenProvider>
                      <Component {...pageProps} />
                    </LoginTokenProvider>
                  </CartIdProvider>
                </BreakpointsProviderV2>
              </BreakpointsProvider>
            </ApolloProvider>
          </I18nProvider>
        </PortalTarget>
      </SSRProvider>
    </ActiveStoreViewProvider>
  )
}

export default App
