import { gql } from '@apollo/client'

export const CraftLinkFragment = gql`
  fragment CraftLinkFragment on CraftLinkFieldLink {
    url
    target
    customText
    type
  }
`

export const CraftLinkListFragment = gql`
  fragment CraftLinkListFragment on CraftLinks3BlockEntry {
    linkItem {
      ...CraftLinkFragment
    }
  }

  ${CraftLinkFragment}
`

export const CraftIconLinksFragment = gql`
  fragment CraftIconLinksFragment on CraftIconLinksMatrixField {
    ... on CraftIconLinksBlockEntry {
      linkItem {
        ...CraftLinkFragment
      }

      linkIcon {
        ...CraftImageFragment
      }
    }
  }

  ${CraftLinkFragment}
`

export const CraftImageFragment = gql`
  fragment CraftImageFragment on CraftAssetInterface {
    id
    url
    width
    height
    title
    defaultUrl: url(transform: "fullWidth")
  }
`

export const CraftGenericBlockLinkWithoutImageFragment = gql`
  fragment CraftGenericBlockLinkWithoutImageFragment on CraftGenericBlockLinkEntry {
    title
    subtitle
    linkField {
      ...CraftLinkFragment
    }
    text: text1
  }

  ${CraftLinkFragment}
`

export const CraftGenericBlockLinkFragment = gql`
  fragment CraftGenericBlockLinkFragment on CraftGenericBlockLinkEntry {
    ...CraftGenericBlockLinkWithoutImageFragment

    image {
      ...CraftImageFragment
    }
  }

  ${CraftImageFragment}
  ${CraftGenericBlockLinkWithoutImageFragment}
`

export const CraftProductsFragment = gql`
  fragment CraftProductsFragment on CraftProductEntry {
    title
    productSku: text1
  }
`

export const CraftLinkGroupListFragment = gql`
  fragment CraftLinkGroupListFragment on CraftLinkGroupEntry {
    groupTitle

    links {
      ...CraftLinkListFragment
    }
  }
  ${CraftLinkListFragment}
`

export const CraftMenuListsFragment = gql`
  fragment CraftMenuListsFragment on CraftMenuListsMatrixField {
    ... on CraftMenuColumnEntry {
      links {
        ... on CraftLinks2BlockEntry {
          linkItem {
            ...CraftLinkFragment
          }
        }
      }

      linkGroups {
        ... on CraftLinkGroups2BlockEntry {
          linkGroupTitleLink {
            ...CraftLinkFragment
          }

          linkGroupLinks {
            ... on CraftLinkGroupLinkEntry {
              linkItem {
                ... on CraftLinkFieldLink {
                  ...CraftLinkFragment
                }
              }
            }
          }
        }
      }
    }
  }

  ${CraftLinkFragment}
`

export const CraftMenuItemFragment = gql`
  fragment CraftMenuItemFragment on CraftMenuItemEntry {
    title

    image {
      ...CraftImageFragment
      mobileUrl: url(width: 750, height: 350)
    }

    menuLists {
      ...CraftMenuListsFragment
    }

    genericBlockLink {
      ...CraftGenericBlockLinkWithoutImageFragment

      ... on CraftGenericBlockLinkEntry {
        image {
          ...CraftImageFragment
          desktopUrl: url(width: 1250, height: 1250)
          tabletUrl: url(width: 600, height: 600)
          mobileUrl: url(width: 600, height: 600)
        }
      }
    }
  }

  ${CraftImageFragment}
  ${CraftMenuListsFragment}
  ${CraftGenericBlockLinkWithoutImageFragment}
`

export const CraftCustomerServiceSubjectItemFragment = gql`
  fragment CraftCustomerServiceSubjectItemFragment on CraftFaqItemEntry {
    urlKey
    faqTitle
    text
    showOnFaqOverview
  }
`

export const CraftCustomerServiceSubjectFragment = gql`
  fragment CraftCustomerServiceSubjectFragment on CraftFaqPageEntry {
    uid
    slug
    metaTitle
    metaDescription
    title
    order: number
    logo: image {
      ...CraftImageFragment
    }
    image: image2 {
      ...CraftImageFragment
      desktopUrl: url(width: 2500, height: 500)
      tabletUrl: url(width: 1982, height: 600)
      mobileUrl: url(width: 1534, height: 800)
    }
    faqItems {
      ...CraftCustomerServiceSubjectItemFragment
    }
  }
  ${CraftImageFragment}
  ${CraftCustomerServiceSubjectItemFragment}
`

export const CraftImageWithProductOverviewItemFragment = gql`
  fragment CraftImageWithProductOverviewItemFragment on CraftItemsBlockEntry {
    image {
      ...CraftImageFragment
      desktopUrl: url(width: 1250, height: 1250)
      tabletUrl: url(width: 992, height: 992)
      mobileUrl: url(width: 767, height: 767)
    }
    itemTitle
    description
    products {
      ...CraftProductsFragment
    }
  }

  ${CraftImageFragment}
  ${CraftProductsFragment}
`

export const CraftUspFragment = gql`
  fragment CraftUspFragment on CraftUspsBlockEntry {
    id
    uspItem
    showIcon
  }
`

export const CraftCollapsibleTextsFragment = gql`
  fragment CraftCollapsibleTextsFragment on CraftCollapsibleTextsBlockEntry {
    id
    question
    answer
  }
`

export const CraftSimpleContentFragment = gql`
  fragment CraftSimpleContentFragment on CraftSimpleContentEntry {
    blockTitleWithStyling {
      ... on CraftBlockTitleWithStylingBlockEntry {
        text
        fontSize
      }
    }
    richText
    blockLink {
      ...CraftLinkFragment
    }
    containerWidth
    readMoreTabletAndDesktop
    readMoreLinesTabletAndDesktop
    readMoreMobile
    readMoreLinesMobile
    readMoreText
    readLessText
  }

  ${CraftLinkFragment}
`

export const CraftInformationItemsBlockFragment = gql`
  fragment CraftInformationItemsBlockFragment on CraftInformationItemsBlockEntry {
    itemTitle
    text

    itemLink {
      ...CraftLinkFragment
    }

    youtubeId

    image {
      ...CraftImageFragment
      desktopUrl: url(width: 1440)
      tabletUrl: url(width: 991)
      mobileUrl: url(width: 722)
    }
  }

  ${CraftLinkFragment}
  ${CraftImageFragment}
`

export const CraftTableValuesFragment = gql`
  fragment CraftTableValuesFragment on CraftTableValuesEntry {
    rowLabel: title
    richTextSimpleRowValue
  }
`

export const CraftTableItemListFragment = gql`
  fragment CraftTableItemListFragment on CraftTableItemListMatrixField {
    ... on CraftTableValuesEntry {
      ...CraftTableValuesFragment
    }
  }

  ${CraftTableValuesFragment}
`

export const CraftTableFragment = gql`
  fragment CraftTableFragment on CraftTableEntry {
    image {
      ...CraftImageFragment
      desktopUrl: url(width: 1440)
      tabletUrl: url(width: 991)
      mobileUrl: url(width: 722)
    }

    tableItemList {
      ...CraftTableItemListFragment
    }
  }

  ${CraftImageFragment}
  ${CraftTableItemListFragment}
`

export const CraftPageBlocksFragment = gql`
  fragment CraftPageBlocksFragment on CraftPageBlocksMatrixField {
    ... on CraftHeroEntry {
      variant
      cloudflareVideoId

      titleWithPartialStyling {
        ... on CraftTitleWithPartialStyling2BlockEntry {
          text
          fontWeight
          fontSize
          startOnNewLine
          uppercase
        }
      }

      background {
        ...CraftImageFragment
        desktopUrl: url(width: 2500, height: 2000)
        tabletUrl: url(width: 991, height: 991)
        mobileUrl: url(width: 722, height: 1115)
      }

      heroLinks {
        ... on CraftHeroLinksBlockEntry {
          buttonType
          linkItem {
            ... on CraftLinkFieldLink {
              ...CraftLinkFragment
            }
          }
        }
      }
    }

    ... on CraftNewsEntry {
      newsTitle
      newsSubtitle
      newsItems {
        ...CraftGenericBlockLinkWithoutImageFragment

        ... on CraftGenericBlockLinkEntry {
          image {
            ...CraftImageFragment
            desktopUrl: url(width: 720, height: 720)
            tabletUrl: url(width: 495, height: 495)
            mobileUrl: url(width: 722, height: 722)
          }
        }
      }
    }

    ... on CraftFeaturedCategoriesEntry {
      featuredCategoriesTitle
      anchorLinkId
      categories {
        ...CraftGenericBlockLinkFragment
      }
    }

    ... on CraftHighlightBannerLink2Entry {
      highlightedItems: highlightedItem {
        ...CraftGenericBlockLinkWithoutImageFragment

        ... on CraftGenericBlockLinkEntry {
          image {
            ...CraftImageFragment
            desktopUrl: url(width: 1440, height: 518)
            desktopCustomUrl: url(width: 1440, height: 960)
            tabletUrl: url(width: 991, height: 357)
            tabletCustomUrl: url(width: 991, height: 660)
            mobileUrl: url(width: 722, height: 260)
          }
        }
      }
      fullWidth
      showIcon
      variant
    }

    ... on CraftProductRecommendationsEntry {
      blockTitle
      subtitle
      tweakwiseTemplateId
    }

    ... on CraftImageContentBanner2Entry {
      anchorLinkId
      richText
      imageAlignment
      imageAlignmentMobile
      colorTheme
      youtubeId

      titleWithPartialStyling2 {
        ...CraftTitleWithPartialStylingFragment
      }

      image {
        ...CraftImageFragment
        desktopUrl: url(width: 1440, height: 960)
        tabletUrl: url(width: 991, height: 661)
        mobileUrl: url(width: 722, height: 481)
      }

      blockLink {
        ...CraftLinkFragment
      }
    }

    ... on CraftSimpleContentEntry {
      ...CraftSimpleContentFragment
    }

    ... on CraftTextColumnsEntry {
      colorTheme
      containerWidth
      readMoreMobile
      readMoreLinesMobile
      readMoreText
      readLessText

      textColumns {
        ... on CraftTextColumnsBlockEntry {
          columnTitle
          text
        }
      }
    }

    ... on CraftImageWithProductOverviewEntry {
      blockTitle
      text

      items {
        ...CraftImageWithProductOverviewItemFragment
      }
    }

    ... on CraftImageEntry {
      image {
        ...CraftImageFragment
      }
    }

    ... on CraftCompareSpecificationsEntry {
      showLabels
      columns {
        ... on CraftColumnsBlockEntry {
          columnTitle
          subtitle
          image {
            ...CraftImageFragment
          }
          text
          specifications {
            ... on CraftSpecificationEntry {
              specificationLabel
              specificationValue
            }
          }
          columnLink {
            ...CraftLinkFragment
          }
        }
      }
    }

    ... on CraftWizardSliderEntry {
      blockTitle
      text
      wizardSteps {
        ... on CraftWizardStepsBlockEntry {
          image {
            ...CraftImageFragment
            desktopUrl: url(width: 1440, height: 720)
            tabletUrl: url(width: 991, height: 496)
            mobileUrl: url(width: 722, height: 361)
          }
          stepTitle
          text
        }
      }
    }

    ... on CraftAccordionInformationEntry {
      blockTitle
      informationItems {
        ... on CraftInformationItemsBlockEntry {
          ...CraftInformationItemsBlockFragment
        }
      }
    }

    ... on CraftYoutubeVideoEntry {
      youtubeId
      placeholder {
        ...CraftImageFragment
        desktopUrl: url(width: 1440, height: 810)
        tabletUrl: url(width: 991, height: 557)
        mobileUrl: url(width: 722, height: 406)
      }
    }

    ... on CraftHtmlEntry {
      blockTitle
      text
      code
    }
  }

  fragment CraftTitleWithPartialStylingFragment on CraftTitleWithPartialStylingBlockEntry {
    text
    fontWeight
    fontSize
    startOnNewLine
    uppercase
  }

  ${CraftLinkFragment}
  ${CraftImageFragment}
  ${CraftProductsFragment}
  ${CraftInformationItemsBlockFragment}
  ${CraftSimpleContentFragment}
  ${CraftGenericBlockLinkFragment}
  ${CraftGenericBlockLinkWithoutImageFragment}
  ${CraftImageWithProductOverviewItemFragment}
`

export const CraftGenericItemsUspsFragment = gql`
  fragment CraftGenericItemsUspsFragment on CraftUspEntry {
    uid
    title
    text: text1
    image {
      ...CraftImageFragment
      desktopUrl: url(width: 1250, height: 1250)
      tabletUrl: url(width: 600, height: 600)
      mobileUrl: url(width: 600, height: 600)
    }
  }

  ${CraftImageFragment}
`

export const CraftGenericItemsImageContentBannerFragment = gql`
  fragment CraftGenericItemsImageContentBannerFragment on CraftImageContentItemEntry {
    uid
    title
    subtitle
    text: text1
    richText
  }
`

export const CraftGenericImageContentBannerFragment = gql`
  fragment CraftGenericImageContentBannerFragment on CraftImageContentBannerEntry {
    imageAlignment
    imageAlignmentMobile
    genericItem {
      ...CraftGenericItemsImageContentBannerFragment
    }

    image {
      ...CraftImageFragment
    }
  }

  ${CraftImageFragment}
  ${CraftGenericItemsImageContentBannerFragment}
`

export const CraftProductPageFaqItemEntryFragment = gql`
  fragment CraftProductPageFaqItemEntryFragment on CraftProductPageFaqItemEntry {
    title
    answer

    linkField {
      ...CraftLinkFragment
    }
  }
`

export const CraftImageContentBannerListItemsFragment = gql`
  fragment CraftImageContentBannerListItemsFragment on CraftImageContentBannerListItemsMatrixField {
    ... on CraftImageContentBannerEntry {
      ...CraftGenericImageContentBannerFragment
    }
  }

  ${CraftGenericImageContentBannerFragment}
`

export const CraftImageContentBannerListFragment = gql`
  fragment CraftImageContentBannerListFragment on CraftImageContentBannerListEntry {
    imageContentBannerListItems {
      ...CraftImageContentBannerListItemsFragment
    }
  }

  ${CraftImageContentBannerListItemsFragment}
`

export const CraftCollapsibleContentBlockItemListFragment = gql`
  fragment CraftCollapsibleContentBlockItemListFragment on CraftCollapsibleContentBlockItemListMatrixField {
    ... on CraftSimpleContentEntry {
      ...CraftSimpleContentFragment
    }

    ... on CraftImageContentBannerListEntry {
      ...CraftImageContentBannerListFragment
    }

    ... on CraftInformationItemsBlockEntry {
      ...CraftInformationItemsBlockFragment
    }

    ... on CraftTableEntry {
      ...CraftTableFragment
    }
  }

  ${CraftSimpleContentFragment}
  ${CraftImageContentBannerListFragment}
  ${CraftInformationItemsBlockFragment}
  ${CraftTableFragment}
`

export const CraftCollapsibleContentBlockItemsFragment = gql`
  fragment CraftCollapsibleContentBlockItemsFragment on CraftCollapsibleContentBlockItemsMatrixField {
    ... on CraftCollapsibleContentBlockItemEntry {
      title

      collapsibleContentBlockItemList {
        ...CraftCollapsibleContentBlockItemListFragment
      }
    }
  }

  ${CraftCollapsibleContentBlockItemListFragment}
`

export const CraftProductPageBlocksFragment = gql`
  fragment CraftProductPageBlocksFragment on CraftProductPageBlocksMatrixField {
    ... on CraftUspsEntry {
      blockTitle
      genericItems {
        ...CraftGenericItemsUspsFragment
      }
    }

    ... on CraftImageContentBannerEntry {
      ...CraftGenericImageContentBannerFragment
    }

    ... on CraftHighlightBannerLinkEntry {
      highlightedItems: highlightedItem {
        ...CraftGenericBlockLinkWithoutImageFragment

        ... on CraftGenericBlockLinkEntry {
          image {
            ...CraftImageFragment
            desktopUrl: url(width: 2500, height: 900)
            tabletUrl: url(width: 1982, height: 790)
            mobileUrl: url(width: 767, height: 460)
            customUrl: url(width: 1250, height: 833)
          }
        }
      }

      showIcon
      variant
    }

    ... on CraftFaqItemsEntry {
      title

      genericfaqitems {
        ...CraftProductPageFaqItemEntryFragment
      }
    }

    ... on CraftCollapsibleContentBlocksEntry {
      collapsibleContentBlockItems {
        ...CraftCollapsibleContentBlockItemsFragment
      }
    }
  }

  ${CraftImageFragment}
  ${CraftLinkFragment}
  ${CraftGenericItemsUspsFragment}
  ${CraftGenericBlockLinkWithoutImageFragment}
  ${CraftProductPageFaqItemEntryFragment}
  ${CraftCollapsibleContentBlockItemsFragment}
  ${CraftGenericImageContentBannerFragment}
`

export const CraftInformationItemSubjectsFragment = gql`
  fragment CraftInformationItemSubjectsFragment on CraftInformationItemSubjectEntry {
    title
  }
`

export const CraftInformationSubjectItemFieldsFragment = gql`
  fragment CraftInformationSubjectItemFieldsFragment on CraftInformationItemFieldsEntry {
    itemTitle
    richText
    showLink
    informationSubject {
      ...CraftInformationItemSubjectsFragment
    }
  }
  ${CraftInformationItemSubjectsFragment}
`

export const CraftInformationSubjectGoCartsFragment = gql`
  fragment CraftInformationSubjectGoCartsFragment on CraftInformationSubjectGoCartsEntry {
    uid
    slug
    uri
    metaTitle
    metaDescription
    typeHandle
    title
    order: number
    subtitle
    image {
      ...CraftImageFragment
      desktopUrl: url(width: 2500, height: 500)
      tabletUrl: url(width: 1982, height: 600)
      mobileUrl: url(width: 1534, height: 800)
    }
  }
  ${CraftImageFragment}
`

export const CraftInformationSubjectTrampolinesFragment = gql`
  fragment CraftInformationSubjectTrampolinesFragment on CraftInformationSubjectTrampolinesEntry {
    uid
    slug
    uri
    metaTitle
    metaDescription
    typeHandle
    title
    order: number
    subtitle
    image {
      ...CraftImageFragment
      desktopUrl: url(width: 2500, height: 500)
      tabletUrl: url(width: 1982, height: 600)
      mobileUrl: url(width: 1534, height: 800)
    }
  }
  ${CraftImageFragment}
`

export const CraftInformationSubjectRideOnCarsFragment = gql`
  fragment CraftInformationSubjectRideOnCarsFragment on CraftInformationSubjectRideOnCarsEntry {
    uid
    slug
    uri
    metaTitle
    metaDescription
    typeHandle
    title
    order: number
    subtitle
    image {
      ...CraftImageFragment
      desktopUrl: url(width: 2500, height: 500)
      tabletUrl: url(width: 1982, height: 600)
      mobileUrl: url(width: 1534, height: 800)
    }
  }
  ${CraftImageFragment}
`

export const CraftInformationSubjectBalanceBikesFragment = gql`
  fragment CraftInformationSubjectBalanceBikesFragment on CraftInformationSubjectBalanceBikesEntry {
    uid
    slug
    uri
    metaTitle
    metaDescription
    typeHandle
    title
    order: number
    subtitle
    image {
      ...CraftImageFragment
      desktopUrl: url(width: 2500, height: 500)
      tabletUrl: url(width: 1982, height: 600)
      mobileUrl: url(width: 1534, height: 800)
    }
  }
  ${CraftImageFragment}
`

export const CraftInformationSubjectScootersFragment = gql`
  fragment CraftInformationSubjectScootersFragment on CraftInformationSubjectScootersEntry {
    uid
    slug
    uri
    metaTitle
    metaDescription
    typeHandle
    title
    order: number
    subtitle
    image {
      ...CraftImageFragment
      desktopUrl: url(width: 2500, height: 500)
      tabletUrl: url(width: 1982, height: 600)
      mobileUrl: url(width: 1534, height: 800)
    }
  }
  ${CraftImageFragment}
`

export const CraftInformationSubjectPlaygroundEquipmentFragment = gql`
  fragment CraftInformationSubjectPlaygroundEquipmentFragment on CraftInformationSubjectPlaygroundEquipmentEntry {
    uid
    slug
    uri
    metaTitle
    metaDescription
    typeHandle
    title
    order: number
    subtitle
    image {
      ...CraftImageFragment
      desktopUrl: url(width: 2500, height: 500)
      tabletUrl: url(width: 1982, height: 600)
      mobileUrl: url(width: 1534, height: 800)
    }
  }
  ${CraftImageFragment}
`

export const CraftInformationOverviewItemsGoCartsFragment = gql`
  fragment CraftInformationOverviewItemsGoCartsFragment on CraftInformationGoCartsEntry {
    uid
    slug
    uri
    title
    typeHandle

    informationItemShort {
      ...CraftInformationSubjectItemFieldsFragment
    }
  }
  ${CraftInformationSubjectItemFieldsFragment}
`

export const CraftInformationOverviewItemsTrampolinesFragment = gql`
  fragment CraftInformationOverviewItemsTrampolinesFragment on CraftInformationTrampolinesEntry {
    uid
    slug
    uri
    title
    typeHandle

    informationItemShort {
      ...CraftInformationSubjectItemFieldsFragment
    }
  }
  ${CraftInformationSubjectItemFieldsFragment}
`

export const CraftInformationOverviewItemsRideOnCarsFragment = gql`
  fragment CraftInformationOverviewItemsRideOnCarsFragment on CraftInformationRideOnCarsEntry {
    uid
    slug
    uri
    title
    typeHandle

    informationItemShort {
      ...CraftInformationSubjectItemFieldsFragment
    }
  }
  ${CraftInformationSubjectItemFieldsFragment}
`

export const CraftInformationOverviewItemsBalanceBikesFragment = gql`
  fragment CraftInformationOverviewItemsBalanceBikesFragment on CraftInformationBalanceBikesEntry {
    uid
    slug
    uri
    title
    typeHandle

    informationItemShort {
      ...CraftInformationSubjectItemFieldsFragment
    }
  }
  ${CraftInformationSubjectItemFieldsFragment}
`

export const CraftInformationOverviewItemsScootersFragment = gql`
  fragment CraftInformationOverviewItemsScootersFragment on CraftInformationScootersEntry {
    uid
    slug
    uri
    title
    typeHandle

    informationItemShort {
      ...CraftInformationSubjectItemFieldsFragment
    }
  }
  ${CraftInformationSubjectItemFieldsFragment}
`

export const CraftInformationOverviewItemsPlaygroundEquipmentFragment = gql`
  fragment CraftInformationOverviewItemsPlaygroundEquipmentFragment on CraftInformationPlaygroundEquipmentEntry {
    uid
    slug
    uri
    title
    typeHandle

    informationItemShort {
      ...CraftInformationSubjectItemFieldsFragment
    }
  }
  ${CraftInformationSubjectItemFieldsFragment}
`

export const CraftInformationDetailGoCartsFragment = gql`
  fragment CraftInformationDetailGoCartsFragment on CraftInformationGoCartsEntry {
    uid
    slug
    metaTitle
    metaDescription
    title
    image {
      ...CraftImageFragment
      desktopUrl: url(width: 2500, height: 500)
      tabletUrl: url(width: 1982, height: 600)
      mobileUrl: url(width: 1534, height: 800)
    }
    pageBlocks {
      ...CraftPageBlocksFragment
    }
    showRelatedQuestions: lightswitchChecked
  }
  ${CraftImageFragment}
  ${CraftPageBlocksFragment}
`

export const CraftInformationDetailTrampolinesFragment = gql`
  fragment CraftInformationDetailTrampolinesFragment on CraftInformationTrampolinesEntry {
    uid
    slug
    metaTitle
    metaDescription
    title
    image {
      ...CraftImageFragment
      desktopUrl: url(width: 2500, height: 500)
      tabletUrl: url(width: 1982, height: 600)
      mobileUrl: url(width: 1534, height: 800)
    }
    pageBlocks {
      ...CraftPageBlocksFragment
    }
    showRelatedQuestions: lightswitchChecked
  }
  ${CraftImageFragment}
  ${CraftPageBlocksFragment}
`

export const CraftInformationDetailRideOnCarsFragment = gql`
  fragment CraftInformationDetailRideOnCarsFragment on CraftInformationRideOnCarsEntry {
    uid
    slug
    metaTitle
    metaDescription
    title
    image {
      ...CraftImageFragment
      desktopUrl: url(width: 2500, height: 500)
      tabletUrl: url(width: 1982, height: 600)
      mobileUrl: url(width: 1534, height: 800)
    }
    pageBlocks {
      ...CraftPageBlocksFragment
    }
    showRelatedQuestions: lightswitchChecked
  }
  ${CraftImageFragment}
  ${CraftPageBlocksFragment}
`

export const CraftInformationDetailBalanceBikesFragment = gql`
  fragment CraftInformationDetailBalanceBikesFragment on CraftInformationBalanceBikesEntry {
    uid
    slug
    metaTitle
    metaDescription
    title
    image {
      ...CraftImageFragment
      desktopUrl: url(width: 2500, height: 500)
      tabletUrl: url(width: 1982, height: 600)
      mobileUrl: url(width: 1534, height: 800)
    }
    pageBlocks {
      ...CraftPageBlocksFragment
    }
    showRelatedQuestions: lightswitchChecked
  }
  ${CraftImageFragment}
  ${CraftPageBlocksFragment}
`

export const CraftInformationDetailScootersFragment = gql`
  fragment CraftInformationDetailScootersFragment on CraftInformationScootersEntry {
    uid
    slug
    metaTitle
    metaDescription
    title
    image {
      ...CraftImageFragment
      desktopUrl: url(width: 2500, height: 500)
      tabletUrl: url(width: 1982, height: 600)
      mobileUrl: url(width: 1534, height: 800)
    }
    pageBlocks {
      ...CraftPageBlocksFragment
    }
    showRelatedQuestions: lightswitchChecked
  }
  ${CraftImageFragment}
  ${CraftPageBlocksFragment}
`

export const CraftInformationDetailPlaygroundEquipmentFragment = gql`
  fragment CraftInformationDetailPlaygroundEquipmentFragment on CraftInformationPlaygroundEquipmentEntry {
    uid
    slug
    metaTitle
    metaDescription
    title
    image {
      ...CraftImageFragment
      desktopUrl: url(width: 2500, height: 500)
      tabletUrl: url(width: 1982, height: 600)
      mobileUrl: url(width: 1534, height: 800)
    }
    pageBlocks {
      ...CraftPageBlocksFragment
    }
    showRelatedQuestions: lightswitchChecked
  }
  ${CraftImageFragment}
  ${CraftPageBlocksFragment}
`

export const CraftQuickFiltersFragment = gql`
  fragment CraftQuickFiltersFragment on CraftQuickFiltersMatrixField {
    ... on CraftQuickFiltersBlockEntry {
      quickFilterName
      subtitle
      image {
        ...CraftImageFragment
      }
      filterLink {
        ...CraftLinkFragment
      }
    }
  }

  ${CraftImageFragment}
  ${CraftLinkFragment}
`

export const CraftCategoryBannersFragment = gql`
  fragment CraftCategoryBannersFragment on CraftCategoryBannersMatrixField {
    ... on CraftCategoryBannersBlockEntry {
      position
      image {
        ...CraftImageFragment
      }
      bannerTitle
      subtitle
      bannerLink {
        ...CraftLinkFragment
      }
      isVisibleOnFilter
    }
  }

  ${CraftImageFragment}
  ${CraftLinkFragment}
`

export const CraftCollapsibleRichTextsFragment = gql`
  fragment CraftCollapsibleRichTextsFragment on CraftCollapsibleRichTextEntry {
    uid
    title
    richText
  }
`

export const CraftCategoryFragment = gql`
  fragment CraftCategoryFragment on CraftCategoryEntry {
    uid
    slug
    title
    metaTitle
    metaDescription
    subtitle
    image {
      ...CraftImageFragment
      desktopUrl: url(width: 2500, height: 500)
      tabletUrl: url(width: 1982, height: 600)
      mobileUrl: url(width: 1534, height: 800)
    }
    pageTitle: title2
    introduction: richText1
    quickFilters {
      ...CraftQuickFiltersFragment
    }
    categoryBanners {
      ...CraftCategoryBannersFragment
    }
    bottomText: richText2
    seoIntroductionTitle
    seoIntroductionRichText

    collapsibleRichTexts {
      ...CraftCollapsibleRichTextsFragment
    }
  }

  ${CraftImageFragment}
  ${CraftQuickFiltersFragment}
  ${CraftCategoryBannersFragment}
  ${CraftCollapsibleRichTextsFragment}
`

export const CraftCategoryLandingFragment = gql`
  fragment CraftCategoryLandingFragment on CraftCategoryLandingEntry {
    uid
    slug
    title: title2
    metaTitle
    metaDescription
    subtitle
    image {
      ...CraftImageFragment
      desktopUrl: url(width: 2500, height: 500)
      tabletUrl: url(width: 1982, height: 600)
      mobileUrl: url(width: 1534, height: 800)
    }
    parent {
      id
      slug
    }
    pageTitle: title
    introduction: richText1
    quickFilters {
      ...CraftQuickFiltersFragment
    }
    bottomText: richText2
    seoIntroductionTitle
    seoIntroductionRichText

    collapsibleRichTexts {
      ...CraftCollapsibleRichTextsFragment
    }
  }

  ${CraftCollapsibleRichTextsFragment}
  ${CraftQuickFiltersFragment}
`

export const CraftCategoryProductPageBlocksFragment = gql`
  fragment CraftCategoryProductPageBlocksFragment on CraftCategoryProductPageBlocksMatrixField {
    ... on CraftCategoryImageWithProductOverviewEntry {
      blockTitle
      text

      items {
        ... on CraftItems3BlockEntry {
          image {
            ...CraftImageFragment
            desktopUrl: url(width: 1250, height: 1250)
            tabletUrl: url(width: 992, height: 992)
            mobileUrl: url(width: 767, height: 767)
          }
          itemTitle
          description
          products {
            ...CraftProductsFragment
          }
        }
      }
    }

    ... on CraftHtml2Entry {
      blockTitle
      text
      code
    }
  }

  ${CraftImageFragment}
  ${CraftProductsFragment}
`

export const CraftImageWithProductOverviewsFragment = gql`
  fragment CraftImageWithProductOverviewsFragment on CraftImageWithProductOverview2Entry {
    blockTitle
    text

    items: items2 {
      ... on CraftItems2BlockEntry {
        image {
          ...CraftImageFragment
          desktopUrl: url(width: 1250, height: 1250)
          tabletUrl: url(width: 992, height: 992)
          mobileUrl: url(width: 767, height: 767)
        }
        itemTitle
        description
        products {
          ...CraftProductsFragment
        }
      }
    }
  }

  ${CraftImageFragment}
  ${CraftProductsFragment}
`
